<template>
  <el-container class="authmgr-import-main">
    <el-main>
      <el-breadcrumb separator-class="el-icon-arrow-right" style="height:20px;">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/AuthMgr/Index'}">权限管理</el-breadcrumb-item>
        <el-breadcrumb-item>导入权限</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card v-if="list.length==0">
        <div style="color:gray;font-size:13px;">无可用权限,请使用侧边工具导入可用的权限</div>
      </el-card>
      <el-card v-for="(l,l_index) in list" :key="l_index" class="auth-list">
        <template #header>
          <span v-text="l.module_id"></span>
          <div class="header-btns" style="display:none;">
            <el-button type="text" size="mini">新增权限</el-button>
            <el-button type="text" size="mini">检测权限</el-button>
          </div>
        </template>
        <std-table v-if="showtable" ref="module_table" :cols="tbconfig.cols" :data="l.paths" auto-height force-refresh
          :filter-function="refreshFilter" @selection-change="onSelectionChange($event,l)" :allow-select-function="allowSelectFunction">
          <template #action="{row}">
            <div v-if="row.exists!==true" style="color:green;text-align:center;">new</div>
          </template>
          <template #info="{row,col}">
            <div v-text="row[col.prop]" :class="{isnew:!row.exists}"></div>
          </template>
        </std-table>
      </el-card>
    </el-main>
    <el-aside>
      <el-card class="upload-box" style="min-height:100px;">
        <template #header>
          获取待导入权限
        </template>
        <el-form ref="detail_form" class="form-jg5" label-width="75px" label-position="right" size="mini">
          <el-form-item label="来源">
            <el-select v-model="form.from_type" placeholder="请选择">
              <el-option v-for="(value,name) in formTypes" :key="name" :label="value" :value="name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择模块" v-if="form.from_type=='api'">
            <el-select v-model="form.module_id" placeholder="请选择" :disabled="form.all_module">
              <el-option v-for="item in apimodules" :key="item" :label="item" :value="item">
              </el-option>
            </el-select><br />
            <el-checkbox v-model="form.all_module" size="mini" :checked="false">读取全部</el-checkbox>
          </el-form-item>
          <el-form-item label="文件上传" v-if="form.from_type=='upload'">
            <el-upload class="upload-demo" v-bind="uploadConfig" :file-list="upload_file" :data="upload_data">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处<br />或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传Json格式文件,后缀可以为.json或.txt</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="链接" v-if="form.from_type=='url'">
            <el-input v-model.trim="form.url"></el-input>
          </el-form-item>
          <el-form-item>
            <span style="color: gray;font-size: 13px;">来源需为swagger文档</span>
          </el-form-item>
          <el-form-item>
            <div style="text-align:right;">
              <el-checkbox v-model="hideExists">隐藏已存在权限</el-checkbox><br />
            </div>
          </el-form-item>
        </el-form>

        <div style="text-align:right;">
          <el-button type="primary" size="mini" :disabled="!hasRecord" @click="onSaveAll">全部保存</el-button>
          <el-button type="primary" size="mini" :disabled="!hasSelect" @click="onSaveSelect">保存选中</el-button>
          <el-button type="primary" size="mini" @click="onSearch">获取</el-button>
        </div>
      </el-card>
    </el-aside>
  </el-container>
</template>
<script>
export default {
  data() {
    let uploadFile = []
    return {
      list: [],
      form: {
        from_type: 'api',
        url: '',
        module_id: '',
        all_module: false
      },
      apimodules: [],
      hideExists: true,
      showtable: true,
      upload_file: uploadFile,
      upload_data: {},
      uploadConfig: {
        drag: true,
        action: this.UploadPath,
        'auto-upload': false,
        ref: 'upload',
        accept: 'application/json,text/plain',
      },
      tbconfig: {
        cols: [
          { prop: 'desc', label: '描述', format: 'info', width: 200 },
          { prop: 'path', label: '地址', format: 'info' },
          { format: 'action', width: 60 }
        ]
      },
      hasSelect: false
    }
  },
  computed: {
    UploadPath() {
      return this.$store.getters.ApiPath + '/values/dft/upload'
    },
    formTypes() {
      return {
        upload: '文档上传',
        api: 'Api中检测',
        url: "按网址读取"
      }
    },
    hasRecord() {
      for (const item of this.list) {
        if (item.paths.length > 0) {
          return true;
        }
      }
      return false
    }
  },
  methods: {
    refreshFilter() {
      if (this.hideExists) {
        return {
          exists: false
        }
      }
      return {}
    },
    onClose() {
      this.$emit('cancel')
    },
    onSearch() {
      let form = this.form
      if (this.form.from_type == 'api') {
        this.Bus.ActionFunc("Auth-ReadFromApi", {
          module_id: form.module_id,
          all_module: form.all_module
        }, data => {
          this.$set(this, 'list', data.list)
        })
      }
      // let vali = this.Bus.validData(postData, {
      //   require: [
      //     { prop: 'module_id', name: '模块ID' },
      //     { prop: 'module_desc', name: '模块描述' }
      //   ]
      // })
      // if (!vali) {
      //   return
      // }
    },
    onSelectionChange(selection, module) {
      if (selection.length > 0) {
        this.hasSelect = true
        return
      }

      for (const item of this.$refs.module_table) {
        if (item.multipleSelection.length > 0) {
          this.hasSelect = true
          return
        }
      }
      this.hasSelect = false
    },
    allowSelectFunction(row) {
      if (row.exists) {
        return false
      }
      return true
    },
    StartSave(result) {
      if (result.length == 0) {
        this.Bus.msg_waring('导入条目获取失败')
        return
      }
      this.Bus.msg_confirm('确定要导入吗?', _ => {
        this.Bus.ActionFunc('Auth-ImportNew', {
          data: result
        }, data => {
          this.Bus.msg_success('导入成功!')
          //this.$emit('success')
          //导入成功,跳转到列表
          this.$router.push({ path: '/AuthMgr/Index' })
        })
      })
    },
    onSaveAll() {
      let result = []
      for (const item of this.list) {
        for (const path of item.paths) {
          if (!path.exists) {
            result.push({
              module_id: item.module_id,
              desc: path.desc,
              path: path.path
            });
          }
        }
      }
      this.StartSave(result)
    },
    onSaveSelect() {
      let result = []
      for (let i = 0; i < this.$refs.module_table.length; i++) {
        let item = this.$refs.module_table[i]
        let module_id = this.list[i].module_id
        for (const path of item.multipleSelection) {
          result.push({
            module_id: module_id,
            desc: path.desc,
            path: path.path
          })
        }
      }
      this.StartSave(result)
    }
  },
  mounted() {
    this.Bus.ActionFunc("Auth-GetAllModuleName", {}, data => {
      this.$set(this, 'apimodules', data.list)
      if (data.list.length > 0) {
        this.$set(this.form, 'module_id', data.list[0])
      }
    })
  }
}
</script>
<style lang="scss">
.authmgr-import-main {
  width: 1200px;
  margin: 0 auto;
  > .el-main {
    padding: 0px 20px 10px 0px !important;

    .isnew {
      color: green;
    }
    > .el-card {
      margin-bottom: 10px;
    }

    > .auth-list {
      .header-btns {
        float: right;
        margin-top: -5px;
      }
      .el-card__body {
        padding: 0;
        .std-table {
          width: 100%;
        }
      }
    }
  }
  > .el-aside {
    overflow: visible;
    padding-bottom: 10px;

    .upload-box {
      .el-card__header {
        //padding: 5px 10px;
        font-size: 14px;
        font-family: 微软雅黑;
      }
      .upload-demo {
        .el-upload {
          width: 100%;
          .el-upload-dragger {
            width: 100%;
            height: auto;
            .el-icon-upload {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
</style>